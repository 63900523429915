.button {
  font: inherit;
  cursor: pointer;
  background-color: #fcc827;
  border: 1px solid #fcc827;
  color: white;
  padding: 0.25rem 2rem;
  border-radius: 20px;
  font-weight: bold;
}

.button:hover,
.button:active {
  background-color: #038f75;
  border-color: #038f75;
}
