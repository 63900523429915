.form {
  text-align: right;
}

.form button {
  font: inherit;
  cursor: pointer;
  background-color: #01b091;
  border: 1px solid #01b091;
  color: white;
  padding: 0.25rem 2rem;
  border-radius: 20px;
  font-weight: bold;
}

.form button:hover,
.form button:active {
  background-color: #038f75;
  border-color: #038f75;
}

.error__message {
  position: absolute;
  bottom: 0;
  right: 0;
}
