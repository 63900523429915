.summary {
  text-align: center;
  /* max-width: 45rem; */
  width: 100%;
  margin: auto;
  margin-top: 5rem;
  /* margin-top: -10rem; */
  position: relative;
  background-color: transparent;
  color: white;

  padding: 0 1rem;
  box-shadow: unset;
}

.summary h2 {
  font-size: 3.5rem;
  margin: 0;
}
