.card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  background-color: white;
}
